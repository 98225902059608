<template>
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" :class="cls" :style="innerStyle" :stroke-width="strokeWidth" :stroke-linecap="strokeLinecap" :stroke-linejoin="strokeLinejoin"><path d="M25.1 3.9c.2.1.4.3.5.5l6.8 10L44 17.8c1.1.3 1.7 1.4 1.4 2.5-.1.2-.2.5-.3.7l-7.4 9.5.4 12c0 1.1-.8 2-1.9 2.1-.2 0-.5 0-.7-.1L24 40.4l-11.3 4.1c-1 .4-2.2-.2-2.6-1.2-.1-.3-.1-.6-.1-.8l.4-12L3 20.9c-.7-.9-.5-2.1.4-2.8.2-.2.4-.3.7-.3l11.6-3.4 6.8-10c.5-.9 1.7-1.1 2.6-.5ZM24 9.1l-5.9 8.7-10.1 3 6.5 8.3-.3 10.5 9.9-3.6 9.9 3.6-.3-10.5 6.5-8.3-10.1-3L24 9.1Zm5 11.5c.8 0 1.5.5 1.8 1.2.3.7.1 1.6-.5 2.1L24 29.6h5c1 0 1.9.9 1.9 1.9 0 1-.9 1.9-1.9 1.9H19c-.8 0-1.5-.5-1.8-1.2-.3-.7-.1-1.6.5-2.1l6.3-5.7h-5c-1 0-1.9-.9-1.9-1.9 0-1 .9-1.9 1.9-1.9h10Z" fill="currentColor" stroke="none"></path></svg>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { getPrefixCls } from '../../_utils/global-config';
import { isNumber } from '../../_utils/is';

export default defineComponent({
  name: 'IconQqZone',
  props: {
    size: {
      type: [Number, String],
    },
    strokeWidth: {
      type: Number,
      default: 4
    },
    strokeLinecap: {
      type: String,
      default: 'butt',
      validator: (value: any) => {
        return ['butt', 'round', 'square'].includes(value);
      }
    },
    strokeLinejoin: {
      type: String,
      default: 'miter',
      validator: (value: any) => {
        return ['arcs', 'bevel', 'miter', 'miter-clip', 'round'].includes(value);
      }
    },
    rotate: Number,
    spin: Boolean
  },
  setup(props) {
    const prefixCls = getPrefixCls('icon');
    const cls = computed(() => [prefixCls, `${prefixCls}-qq-zone`, { [`${prefixCls}-spin`]: props.spin }]);
    const innerStyle = computed(() => {
      const styles: CSSProperties = {};
      if (props.size) {
        styles.fontSize = isNumber(props.size) ? `${props.size}px` : props.size;
      }
      if (props.rotate) {
        styles.transform = `rotate(${props.rotate}deg)`;
      }
      return styles;
    });

    return {
      cls,
      innerStyle
    };
  }
});
</script>
